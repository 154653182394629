import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";

import { AppDetails3} from "../../components/AppDetails";
const DecisionAIPage = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="Behavioral AI - AISciLabs"
        favicon="assets/images/logo/logo-2.png"
      />
      <LayoutFluid
        wrapperClass="app-details-page"
        activeClass="active"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="white-content"
        logoWhite="assets/images/logo/logo-1.png"
      >
        <main>
          <BreadCrumbOne
            title="Get to Know Behavioral Intelligence"
            pageName="Behavioral Intelligence"
            curentPageName="Behavioral Intelligence"
          />
          <AppDetails3/>
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default DecisionAIPage;
