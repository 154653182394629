import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { Fragment } from "react";
import {FunctionalAIContent,DecisionAIContent, BehavioralAIContent} from "../MoreDetail"


const MoreDetail = ({title}) => {
  const [activeTab, setActiveTab] = useState("1");
  console.log(title);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const setContent = (cont) => {
    console.log(cont);
    if (cont === 'functional-ai'){
      return <FunctionalAIContent/>

    } else if (cont ==='decision-ai'){
      return <DecisionAIContent/>
    } else if (cont ==='behavioral-ai'){
      return <BehavioralAIContent/>
    }
  }

  return (
    <Fragment>
      <div className="overall-review">
        <Row>
          <Col lg="6" xs="12">
            <div className="overall-review-nav ul-li clearfix">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Get to Know
                  </NavLink>
                </NavItem>
              

              </Nav>
            </div>
          </Col>

        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane id="about-pane" tabId="1">
            {setContent(title)}

          </TabPane>
          <TabPane id="overview-pane" tabId="2">
          </TabPane>
          <TabPane id="review-pane" tabId="3">

          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  );
};

export default MoreDetail;
