import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";

import { MoreDetail } from "../MoreDetail";

const AppDetails3 = () => {
  return (
    <Fragment>
      {/* <!-- app-details-section - start
		================================================== --> */}
      <section
        id="app-details-section"
        className="app-details-section sec-ptb-160 pb-0 clearfix"
      >
        <Container>
          <Row className="justify-content-lg-start justify-content-md-center">
            <Col lg="9" xs="12">
              <div className="app-details-content clearfix">
                
                <MoreDetail title="behavioral-ai" />
              </div>
            </Col>

            <Col lg="3" md="6" xs="12">
              <aside
                id="sidebar-section"
                className="sidebar-section app-details-sidebar clearfix"
              >

                {/* <!-- widget_tag_cloud - end --> */}
              </aside>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <!-- app-details-section - end
		================================================== --> */}
    </Fragment>
  );
};

export default AppDetails3;
