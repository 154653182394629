import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";
const SideBar = ({ sidebarIsOpen, handleSidebar }) => {
  return (
    <Fragment>
      <div className="sidebar-menu-wrapper">
        <div
          id="sidebar-menu"
          className={`sidebar-menu ${sidebarIsOpen ? "active" : null}`}
        >
          <div className="dismiss text-right mb-60 clearfix">
            <span className="close-btn" onClick={handleSidebar}>
              <i className="flaticon-cancel-music"></i>
            </span>
          </div>

          <div className="btns-group ul-li mb-60 clearfix">
            <h2 className="sidebar-title mb-30"></h2>
            <p className="mb-45">
         
            </p>
            <ul className="clearfix">
              {/* <li>
                <Link to="#!" className="store-btn bg-default-blue">
                  <span className="icon">
                    <i className="flaticon-google-play"></i>
                  </span>
                  <strong className="title-text">
                    <small>available on</small>
                    google play
                  </strong>
                </Link>
              </li>
              <li>
                <Link to="#!" className="store-btn bg-default-black">
                  <span className="icon">
                    <i className="flaticon-apple"></i>
                  </span>
                  <strong className="title-text">
                    <small>available on</small>
                    apple store
                  </strong>
                </Link>
              </li> */}
            </ul>
          </div>

          <ul className="menu-list mb-60 list-unstyled components clearfix">

            <li className="menu-item-has-child">
              <Link to="#?" id="toggler1">
                Home
              </Link>

            </li>

            <li>
              <Link to="about-us">About Us</Link>
            </li>
            <li className="menu-item-has-child">
              <Link to="#?" id="toggler3">
                Labs
              </Link>
              <UncontrolledCollapse toggler="#toggler3">
                <ul className="sub-menu list-unstyled">
                  <li>
                  <Link to="functional-ai">
                      AISciLabs
                    </Link>
                    </li>
                    <li>
                    <Link to="decision-ai">
                      DeepQX
                    </Link>
                  </li>
                  <li>
                    <Link to="behavioral-ai">
                      aimdax
                    </Link>
                  </li>
                </ul>
              </UncontrolledCollapse>
            </li>
            <li>
              <Link to="contact-us">Contact With Us</Link>
            </li>

          </ul>

          <div className="contact-info ul-li-block mb-60 clearfix">
            <h2 className="sidebar-title mb-30">contact info</h2>
            <ul className="clearfix">
              <li>
                <span className="icon">
                  <i className="uil uil-map-marker"></i>
                </span>
                The Black Church,
                Saint Mary's Place North
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-map-pin"></i>
                </span>
                Dublin 7,D07 P4AX,Ireland
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-envelope-alt"></i>
                </span>
                hello@aiscilabs.com
              </li>
              {/* <li>
                <span className="icon">
                  <i className="uil uil-phone"></i>
                </span>
                
              </li> */}
            </ul>
          </div>

          <div className="social-links ul-li clearfix">
            <h2 className="sidebar-title mb-30">follow us</h2>
            <ul className="clearfix">
              <li>
                <Link to="#!">
                  <i className="fab fa-facebook-f"></i>
                </Link>
              </li>
              <li>
                <Link to="#!">
                  <i className="fab fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link to="#!">
                  <i className="fab fa-pinterest-p"></i>
                </Link>
              </li>
              <li>
                <Link to="#!">
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          onClick={handleSidebar}
          className={`overlay ${sidebarIsOpen ? "active" : null}`}
        ></div>
      </div>
    </Fragment>
  );
};

export default SideBar;
