import React, { useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import TitleSection from "../Titlesection/TitleSection";
import titleTwo from "../../data/title/title-two.json";
import deepqx_lg from "../../assets/images/brands/deepqxnew.png"
import aiscilabs_lg from "../../assets/images/brands/aiscilabsg.png"
import aimdax_lg from "../../assets/images/brands/aimdaxlogo.png"
import neurvoi_lg from "../../assets/images/brands/neurvoi_b_lgo.png"
import brand1_bg from "../../assets/images/features/img-1.png"
import brand2_bg from "../../assets/images/features/img-2.png"
import brand3_bg from "../../assets/images/features/img-3.png"
const [{ title, Paragraph }] = titleTwo;

const FeaturesNav = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <section
        id="extra-features-section"
        className="extra-features-section sec-ptb-160 bg-light-gray clearfix"
      >
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" lg="6" md="8">
              {
                <TitleSection spacer="mb-100" textAlign="text-center">
                  <h2 className="title-text mb-30">{title}</h2>
                  <p className="paragraph-text mb-0">{Paragraph}</p>
                </TitleSection>
              }
            </Col>
          </Row>

          <div className="features-nav ul-li-center mb-100">
            {/* class="nav" */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <span className="item-icon">
                    <img width={"80px"}
                      src={aiscilabs_lg}
                      alt="icon_not_found"
                    />
                  </span>

                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src={deepqx_lg}
                      alt="icon_not_found"
                    />
                  </span>

                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src={aimdax_lg}
                      alt="icon_not_found"
                    />
                  </span>

                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src={neurvoi_lg}
                      alt="icon_not_found"
                    />
                  </span>

                </NavLink>
              </NavItem>

              
            </Nav>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Towards
                      <h1 className="break-point">
                        Functional AI
                      </h1>
                    </h3>
                    <p className="mb-30">
                      Bringing advanced disciplines to engineer an intelligent decision based systems harnessing the power of Functional AI.
                      Utilizing cutting-edge technologies , machine learning , Deep learning and AI processes and models , the Labs seek to provide AI/DI powered Proprietary Systems
                      in order to make critical decisions with more confidence.


                    </p>
                    <div className="instructions-list ul-li-block">
                    <div className="btns-group ul-li-right clearfix">
                        <ul className="clearfix">
                          <li>
                            <Link to="/functional-ai" className={""}>
                              Learn More about Functional AI
                            </Link>
                          </li></ul></div>

                    </div>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src={aiscilabs_lg}
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      LabQX
                      <h1 className="break-point">
                        The premier division and lab for Quantitative Algorithmic trading,research and development
                      </h1>
                    </h3>
                    <p className="mb-30">
                      With the capability to develop Advanced Algorithms ,predictive models, uncover relationships, and develop strategies,the LabQX is responsible for building and deploying powerful Real-time systems to make the optimal decisions utilizing the Quantitative approach.


                    </p>
                    <div className="instructions-list ul-li-block">
                    <div className="btns-group ul-li-right clearfix">
                        <ul className="clearfix">
                          <li>
                            <Link to="/decision-ai" className={""}>
                              Learn More about Decision Intelligence(DI)
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src={deepqx_lg}
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      aimdax
                      <h1 className="break-point">
                        Behavioral Intelligence lab
                      </h1>
                    </h3>
                    <p className="mb-30">
                      It's devoted to exploring and deploying the ultimate user experience by understanding and developing AI oriented algorithms , processes and systems to detect and understand users behavior 
                    </p>
                    <div className="instructions-list ul-li-block">
                    <div className="btns-group ul-li-right clearfix">
                        <ul className="clearfix">
                          <li>
                            <Link to="/behavioral-ai" className={""}>
                              Learn More about Behavioral Intelligence
                            </Link>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </Col>

                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src={aimdax_lg}
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      NEURVOI
                      <h1 className="break-point">
                        AI-Driven Voice Intelligence
                      </h1>
                    </h3>
                    <p className="mb-30">
                      It's devoted to exploring and finding realtime clues in human voice cues utilizing advanced AI algorithms 
                    </p>
                    <div className="instructions-list ul-li-block">
                    <div className="btns-group ul-li-right clearfix">
                        <ul className="clearfix">
                          <li>
                            <a href="https://neurvoi.ai" target="_blank" className={""}>
                              Learn More about Neurvoi
                            </a>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </Col>

                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src={neurvoi_lg}
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </section>
    </div>
  );
};

export default FeaturesNav;
