import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";
import { Terms } from "../../components/Terms";


const TermsPage = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="Terms & Conditions  - AISciLabs"
        favicon="assets/images/logo/logo-2.png"
      />
      <LayoutFluid
        wrapperClass="about-page"
        activeClass="active"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="white-content"
        logoWhite="assets/images/logo/logo-1.png"
      >
        <main>
          <BreadCrumbOne
            title="Terms & Conditions "
            pageName="Terms & Conditions "
            curentPageName="Terms & Conditions "
          />
          <Terms sectionSpace="sec-ptb-160" />

          {/* <Team sectionSpace="sec-ptb-160 pt-0" /> */}

        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default TermsPage;
