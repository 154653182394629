import React, { Fragment } from "react";
import { Link as Scroll } from "react-scroll";
import { Link } from "react-router-dom";
const Navigation = ({ activeClass }) => {
  return (
    <Fragment>
      <nav className="main-menubar ul-li-right clearfix">
        <ul className="clearfix">
          {/* <li
           
          >
            <Link to="/">home</Link>
      
          </li> */}

          {/* <li>
         
          <Link to="/about-us">about</Link>
          </li> */}
        
   
          {/* <li>
            <Scroll smooth spy to="extra-features-section" duration={1} offset={-50}>Brands</Scroll>
          </li> */}

       
   
    
        </ul>
      </nav>
    </Fragment>
  );
};

export default Navigation;
