import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";

// import { Map } from "../../components/Map";
import {Contact} from "../../components/Contact";
const ContactPage = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="Contact Us - AISciLabs"
        favicon="assets/images/logo/logo-2.png"
      />
      <LayoutFluid
        wrapperClass="contact-page"
        activeClass="active"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="white-content"
        logoWhite="assets/images/logo/logo-1.png"
      >
        <main>
          <BreadCrumbOne
            title="contact with us"
            pageName="contact us"
            curentPageName="contact us"
          />
          <Contact sectionSpace="sec-ptb-160"/>
          {/* <Map /> */}
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default ContactPage;
