import React from "react";
import axios from "axios";
import FormikForm from "./Formik";

class ContactForm extends React.Component {
  render() {
    let values = {
      name: "",
      subject: "",
      msg: "",
      email: "",
    };
    return <FormikForm values={values} handleSubmit={this.handleFormSubmit} />;
  }
  handleFormSubmit = async (values, setSubmitting) => {
    console.log(values);
    // e.preventDefault();

    console.log("submitted");
    // console.log(email)
    values && await axios.post('api/contact',{
        email: values.email,
        name: values.name,
        msg:values.subject + '-' + values.msg,
    },{headers:{apikey:'iv1SJz1X9FqGy5nc6+EiGQVT'}}).then(function (response){
      // console.log(response);
     setSubmitting(false);
    
    }).catch(function (error) {
      setSubmitting(false);
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
  
    });
    // setTimeout(() => {
    //   setSubmitting(false);
    // }, 1000);
  };
}

export default ContactForm;
