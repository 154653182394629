import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";

import Contacttitle from "../../data/contact/contact-title.json";
// import ContactData from "../../data/contact/contact.json";
import ContactForm from "../../components/Formik";
const [{ title, subTitle }] = Contacttitle;
const Contact = ({ sectionSpace }) => {
  return (
    
    <Fragment>
      <section
        id="contact-section"
        className={`contact-section ${sectionSpace} clearfix`}
      >
        <Container>


          <Row className="justify-content-lg-between justify-content-md-center">
          <Col lg="5" md="8" xs="12">
          <Row className="justify-content-center">
            <Col lg="12" md="12" xs="12">
              <div className="section-title mb-100 text-center">
                <span className="sub-title mb-15"> {subTitle} </span>
                <h2 className="title-text mb-0">{title}</h2>
              </div>
            </Col>
          </Row>
          {/* <ContactForm /> */}
          </Col>
          <Col lg="6" md="8" xs="12">
          <ContactForm />
          </Col>
          


          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Contact;
