import React, { useRef, useEffect,useState } from "react";
import { Link as LinkS } from "react-scroll";
import { Container, Row, Col } from "reactstrap";
import Parallax from "parallax-js";
import BackgroundSlider from 'react-background-slider'
import Preloader from '../../components/Preloader/preloader';
import image6 from "../../assets/background/background7_opt.jpg"


const Slider = BackgroundSlider['react-background-slider'].default;

const BannerOne = (props) => {
  const scenElement = useRef(null);
  const [imgsLoaded, setImgsLoaded] = useState(false)
  const IMAGES = [image6]
  useEffect(() => {
    const parallaxInstance = new Parallax(scenElement.current, {
      relativeInput: true,
    });
    parallaxInstance.enable();
    return () => parallaxInstance.disable();
  }, []);

  
  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image)
          }, 0)

        loadImg.onerror = err => reject(err)
      })
    }

    Promise.all(IMAGES.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch(err => console.log("Failed to load images", err))
  }, [])

  return (
    
    <React.Fragment>
      {imgsLoaded ? (
      <section id="banner-section" className="banner-section clearfix">
        <div className="mobile-app-banner-1">
        <Slider
                  images={[image6, image6, image6]}

                  duration={60000}
                  transition={0}
                />
          <Container>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                {/* <!-- show on mobile device - start --> */}
                <div className="mobile-banner-image d-none">
                
                </div>
                {/* <!-- show on mobile device - end --> */}
                <div className="banner-content">
                  <h2 className="text-white" style={{backgroundColor:"#00000052"}}>
                    Intelligent Decisions for a Brighter Future.
                    Harnessing the Power of Artificial Intelligence
                    <strong></strong>
                    
                  </h2>
                  <p className="text-white">
                    
                  </p>
                  <div className="btns-group ul-li clearfix sec-ptb-160">
                    <ul className="clearfix">
                      <li>
                        <LinkS
                          
                          smooth spy to="extra-features-section"
                          duration={1} offset={-50}
                          className="store-btn bg-default-black"
                        >
                          <span className="icon">
                            <i className="flaticon-device"></i>
                          </span>
                          <strong className="title-text">
                            <small>discover</small>
                            The Labs
                          </strong>
                        </LinkS>
                      </li>
                      <li> <LinkS smooth spy to="footer-section" className="custom-btn" duration={1} offset={-50}>Get in touch</LinkS></li>
                      {/* <li>
                        <a
                          rel="noopener noreferrer"
                          href="#/"
                          target="_blank"
                          className="store-btn bg-default-blue"
                        >
                          <span className="icon">
                            <i className="flaticon-gear"></i>
                          </span>
                          <strong className="title-text">
                            <small>towards</small>
                            Functional AI
                          </strong>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="5" md="8">
                <div
                  className="banner-image scene"
                  id="scene"
                  ref={scenElement}
                >
             
                </div>
              </Col>
            </Row>
          </Container>

        </div>
      </section>
      ):      
      <section id="banner-section" className="banner-section clearfix">
      <div className="mobile-app-banner-1">
        <Preloader/>
        <Container>
          <Row className="justify-content-lg-between justify-content-md-center">
            <Col lg="6" md="8">
              {/* <!-- show on mobile device - start --> */}
       
              {/* <!-- show on mobile device - end --> */}
              <div className="banner-content">
                <h2 className="text-white">
                
                  <strong></strong>
                  
                </h2>
                <p className="text-white">
                  
                </p>
                <div className="btns-group ul-li clearfix sec-ptb-160">
                  <ul className="clearfix">
                    <li>
              
                    </li>
                  
            
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg="5" md="8">
              <div
                className="banner-image scene"
                id="scene"
                ref={scenElement}
              >
           
              </div>
            </Col>
          </Row>
        </Container>

      </div>
    </section>
    }
    </React.Fragment>
    
  );
};

export default BannerOne;
